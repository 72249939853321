<template>
	<div>
		<!-- Filters -->
		<!-- <data-filters
			:city-filter.sync="cityFilter"
			:status-filter.sync="statusFilter"
			:sector-filter.sync="sectorFilter"
			:donar-filter.sync="donarFilter"
		/> -->

		<b-card no-body class="mb-3">
			<base-table :headers="tableColumns" :items="fetchComplaints" paginate table-top>
				<template #cell(status)="{ item }">
					<b-badge :variant="getGRMStatusColor(item.status)" class="p-50">{{ item.status }}</b-badge>
				</template>
				<template #cell(category.name)="{ item }">
					<p v-if="item.category" class="font-weight-bold">{{ item.category.name }}</p>
					<span v-else class="font-weight-light">Not set</span>
				</template>
				<template #cell(created_at.date)="{ item }">
					<span>{{ new Date(item.created_at.date).toLocaleDateString() }}</span>
				</template>
			</base-table>
		</b-card>
	</div>
</template>

<script>
import store from '@/store';
import { BCard, BBadge } from 'bootstrap-vue';
import BaseTable from '@/components/BaseTable.vue';
import { getGRMStatusColor } from '@/utils/helpers';
// import DataFilters from './DataFilters.vue';

export default {
	components: {
		// DataFilters,
		BCard,
		BBadge,
		BaseTable,
	},

	data: () => ({
		showCharts: false,
		tableColumns: [
			// { key: 'id', sortable: false },
			// { key: 'projectId', sortable: false },
			{ key: 'project.SP_PROJID', label: 'Code', sortable: false },
			// { key: 'project.SP_TITLE', label: 'Project', sortable: false },
			// { key: 'projectNameEnglish', sortable: false },
			{ key: 'project.sector', label: 'sector', sortable: false },
			{ key: 'phone', sortable: false },
			{ key: 'created_at.date', label: 'Date', sortable: false },
			{ key: 'category.name', label: 'Category', sortable: false },
			{ key: 'project.SP_DONER', label: 'Doner', sortable: false },
			{ key: 'project.GOV_AR', label: 'City', sortable: false },
			// { key: 'project.CD_TITLE', label: 'Town', sortable: false },
			{ key: 'complaint', sortable: false },
			// { key: 'responsibility', sortable: false },
			// { key: 'solution', sortable: false },
			{ key: 'status', sortable: false },
			// { key: 'updateDate', sortable: false },
			// { key: 'notes', sortable: false },
		],

		cityFilter: null,
		statusFilter: null,
		sectorFilter: null,
		donarFilter: null,
	}),

	watch: {
		cityFilter() {
			this.$refs.refDataTable.refresh();
		},
	},

	methods: {
		getGRMStatusColor,

		fetchComplaints(ctx, searchQuery) {
			return store.dispatch('complaints/getComplaints', { ...ctx, searchQuery });
		},
	},
};
</script>

<style lang="scss" scoped>
.per-page-selector {
	width: 90px;
}
</style>

<style lang="scss">
.table-responsive {
	margin-bottom: 0;
}
</style>
